/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import guyanaflogo from "./imgs/guyanaflogo.png";
import { Link, NavLink } from "react-router-dom";

import { useLocation } from "react-router-dom";

export default function Navbar() {
  const [isHovered, setIsHovered] = useState(false);
  const [bgOpacity, setBgOpacity] = useState(100); // Set initial value for bg-opacity to 100
  const [maxWidth, setMaxWidth] = useState("max-w-[12vh]");
  const [isAtTop, setIsAtTop] = useState(true);

  const location = useLocation();
  const positionClass =
    location.pathname === ("/galleries" && "/projects") ? "relative" : "fixed";

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.pageYOffset === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollDelta = scrollTop - lastScrollTop;
      const isScrollingUp = scrollDelta < 0;

      setIsAtTop(scrollTop === 0);

      if (window.innerWidth < 640) {
        if (isScrollingUp) {
          setBgOpacity(100);
          setIsHovered(true);
        } else {
          setBgOpacity(50);
          setIsHovered(false);
        }
      } else {
        if (isScrollingUp && scrollTop > 0) {
          setBgOpacity(100);
          setIsHovered(true);
        } else {
          setBgOpacity(50);
          setIsHovered(false);
        }
      }

      const newWidth = scrollTop === 0 ? "max-w-[13vh]" : "max-w-[11vh]";
      setMaxWidth(newWidth);

      lastScrollTop = scrollTop;
    };

    if (window.innerWidth < 640) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (window.innerWidth < 640) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <nav
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      className={`flex duration-200 max-h-40 transition-all opacity-${bgOpacity} fixed max-sm:grid max-sm:place-items-center max-sm:pb-2 w-full bg-lime-900 top-0 z-50 flex-wrap items-center justify-around border-opacity-0 border-b-2 border-b-lime-600 bg-opacity-20 shadow-2xl ${(isHovered ||
        isAtTop) &&
        "opacity-90 shadow-2xl border-opacity-80 bg-opacity-95 duration-200 transition-all"}
        `}
    >
      <Link to="/">
        <img
          src={guyanaflogo}
          className={`container ${maxWidth} max-h-32 hover:animate-pulse transition-all ease max-sm:max-w-[8vh] py-2 font-bold ${
            isHovered || isAtTop ? "opacity-100" : "opacity-10"
          }`}
          alt="guyanaflogo"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        />
      </Link>

      <ul
        className={`flex transition-all ease max-sm:gap-5 sm:pb-2 md:pb-0 text-[10px] sm:text-base md:text-lg 3840:text-2xl sm:gap-5 lg:gap-10 items-center ${
          isHovered || isAtTop ? "opacity-100" : "opacity-10"
        }`}
      >
        <button className="inline-flex items-center transition-all ease-in-out justify-center font-medium rounded-lg text-white hover:underline  hover:underline-offset-4">
          <NavLink to="/">
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? "active underline transition-all duration-500 decoration-2 decoration-pink-500 underline-offset-4"
                    : "opacity-70 contrast-125"
                }
              >
                Home
              </span>
            )}
          </NavLink>
        </button>

        <button className="font-medium text-white hover:underline hover:underline-offset-4 group bg-gradient-to-br">
          <NavLink to="/about">
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? "active underline transition-all duration-500 decoration-2 decoration-pink-500 underline-offset-4"
                    : "opacity-70"
                }
              >
                About
              </span>
            )}
          </NavLink>
        </button>
        <button className="font-medium whitespace-nowrap text-white hover:underline hover:underline-offset-4 group bg-gradient-to-br">
          <NavLink to="/our-team">
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? "active underline transition-all duration-500 decoration-2 decoration-pink-500 underline-offset-4"
                    : "opacity-70"
                }
              >
                Our Team
              </span>
            )}
          </NavLink>
        </button>
        <button className="inline-flex items-center justify-center font-medium text-white hover:underline hover:underline-offset-4 rounded-lg group bg-gradient-to-br">
          <NavLink to="/news">
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? "active underline transition-all duration-500 decoration-2 decoration-pink-500 underline-offset-4"
                    : "opacity-70"
                }
              >
                News
              </span>
            )}
          </NavLink>
        </button>
        <button className="inline-flex items-center justify-center text-white hover:underline hover:underline-offset-4 font-medium rounded-lg group bg-gradient-to-br">
          <NavLink to="/galleries">
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? "active underline transition-all duration-500 decoration-2 decoration-pink-500 underline-offset-4"
                    : "opacity-70"
                }
              >
                Galleries
              </span>
            )}
          </NavLink>
        </button>
        <button className="inline-flex items-center justify-center text-white hover:underline hover:underline-offset-4 font-medium rounded-lg group bg-gradient-to-br">
          <NavLink to="/projects">
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? "active underline transition-all duration-500 decoration-2 decoration-pink-500 underline-offset-4"
                    : "opacity-70"
                }
              >
                Projects
              </span>
            )}
          </NavLink>
        </button>
        <button className="inline-flex items-center justify-center text-white hover:underline hover:underline-offset-4 font-medium rounded-lg group bg-gradient-to-br">
          <NavLink to="/contact">
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? "active underline transition-all duration-500 decoration-2 decoration-pink-500 underline-offset-4"
                    : "opacity-70"
                }
              >
                Contact
              </span>
            )}
          </NavLink>
        </button>
      </ul>
    </nav>
  );
}
