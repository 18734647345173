import React, { useEffect, useState } from 'react';
import Poster2 from './imgs/Poster 2.jpg';
import Poster3 from './imgs/Poster 3.jpg';
import Poster4 from './imgs/Poster4.jpg';

const posters = [Poster2, Poster3, Poster4];

export default function PosterSlide() {
  const [currentPosterIndex, setCurrentPosterIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPosterIndex((prevIndex) => (prevIndex + 1) % posters.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="max-4xl:hidden 4xl:block">
      {posters.map((poster, index) => (
        <img
          key={index}
          src={poster}
          alt={`Poster ${index + 2}`}
          className={`absolute p-1 3840:top-20 top-2 left-5 transition-opacity duration-[2000ms] ${
            index === currentPosterIndex ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            zIndex: index === currentPosterIndex ? 1 : 0,
            width: '400px',
          }}
        />
      ))}
    </div>
  );
}
