import React from "react";
import Poster4 from "./imgs/Poster4.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { motion, useAnimation } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import PosterSlide from "./PosterSlide";

export default function Counseling() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
    threshold: 0.2, // Percentage of the element that needs to be visible to trigger the animation
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const controls = useAnimation();
  const elementRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      const scrollPosition = window.scrollY + window.innerHeight;

      if (element && element.offsetTop < scrollPosition) {
        controls.start({ x: 0, opacity: 1 });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);

  function AllImages() {
    function importAll(r) {
      return r.keys().map(r);
    }

    const context1 = require.context(
      "./imgs/Pictures/MENTAL HEALTH",
      false,
      /\.(jpg|JPG|png|gif)$/
    );

    const listOfImages = importAll(context1).sort();

    return (
      <div className="mx-auto">
        <div className="max-sm:grid align-middle justify-center items-center">
          {/* Swiper carousel */}
          <Swiper
            spaceBetween={-100}
            slidesPerView={1}
            navigation={true}
            loop={true}
            keyboard={{
              enabled: true,
            }}
            zoom={true}
            autoplay={{ delay: 6000, disableOnInteraction: false }}
            speed={500}
            centeredSlides={true}
            breakpoints={{
              // max-sm breakpoint
              3840: {
                slidesPerView: 3,
                spaceBetween: -700,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
              // all other breakpoints
              0: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
            }}
            className="max-sm:w-full"
          >
            {listOfImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  loading="lazy"
                  className="object-contain hover:object-contain xl:object-contain px-2 mx-auto max-sm:px-2 lg:object-cover items-center w-full 3840:w-6/12 max-sm:h-96 3840:h-[20vw] h-[30vw]  max-sm:w-full py-2"
                  src={image}
                  alt="info"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  }

  const styles3 = {
    backgroundImage: `url(https://i.imgur.com/3aQRrj6.jpg)`,
    backgroundPosition: "top",
    backgroundSize: "100% 100%",
    backgroundRepeat: "repeat",
    width: "100%",
    height: "100%",
  };
  return (
    <div
      style={styles3}
      className="pt-20 bg-red-200 max-4xl:py-10 overflow-x-hidden relative"
    >
      <motion.p
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 1.5, ease: "easeOut" }}
        className="font-Manrope max-sm:grid tracking-wide text-slate-900 rounded-3xl max-sm:w-11/12 max-2xl:mx-auto max-2xl:text-center max-2xl:translate-x-0 max-3xl:p-0 mx-auto max-4xl:p-0 max-sm:translate-x-0  whitespace-pre-line text-3xl max-sm:text-center max-sm:text-4xl 3840:text-6xl 3840:mx-auto 3840:text-center pl-96 3840:pl-0 3840:ml-96 max-1366:mx-auto max-1366:pb-5 text=center text-center"
      >
        We provide{" "}
        <b className="font-dancing max-sm:font-medium sm:text-8xl text-6xl pt-2 tracking-tighter max-sm:pb-10 3840:text-9xl">
          Counseling and Kids Mentorship
        </b>
      </motion.p>

      <div className="grid grid-flow-col pb-10">
        <p className="max-2xl:w-10/12 max-2xl:translate-x-0 max-2xl:mx-auto max-sm:tracking-normal max-sm:mx-auto pb-12 max-sm:translate-x-0 max-sm:w-11/12 max-sm:text-justify max-sm:text-xs max-sm:py-1 font-Antiqua w-11/12 pl-[19.5vw] 3840:pl-64 3840:pb-0 max-4xl:pl-0 place-self-center text-justify 3840:mx-auto 3840:text-2xl 3840:w-10/12 3840:pt-10 text-lg leading-6 pt-3">
          Counselling and mentorship have been at the heart of Guyana
          Foundations work. We began in the early days by commissioning a
          research study directed by Serena Coultress from Maastricht University
          to look deeply into the causes of suicide in the country. Her study
          revealed a number of helpful observations. Her executive Summary is
          available to anyone working in this field of study. Resulting from the
          study, a decision was taken to set up community-based Sunrise Centers
          to bring healing at a community level.
          <br></br>
          <br></br>
          Following this, the Foundation invited mental health professionals
          from the Toronto Anger Management Center and the British Association
          of Cognitive and Behavioural Therapy to conduct training programs for
          counsellors. The Foundation also collaborated with the William James
          College for Global Mental Health. The University of Ciego De Avila in
          Cuba where a 5-year MOU was signed to hold Mental Health Conferences.
          The material from these conferences greatly assisted the work we were
          doing on the ground in Guyana. More recently we collaborated with the
          Elliott School of International Affairs and George Washington
          University. The results of the research done during this collaboration
          revealed the need for Comprehensive Sex Education in Guyanese Schools.
          The counselling data we have collected over the years clearly
          indicated that prolonged issues of domestic violence, and various
          forms of abuse are all contributing factors to the poor mental health
          of people. Teaching sex education from an early age is crucial to
          safeguarding people's mental preparedness to deal with these issues
          when they arise.
          <br></br>
          <br></br>
          <img
            src={Poster4}
            alt="poster"
            className=" mx-auto w-3/12 pb-5 hidden max-sm:hidden max-1366:block"
          />
          Our counselling work has brought many people away from the brink of
          suicide. We continue to allot part of our resources to go out into the
          communities to share information on mental illness and to provide lay
          people with a basic road map to help themselves and their loved ones
          and friends. This work continues online and when there are trained
          professionals available, we conduct counselling at our Sunrise
          Centers.{" "}
        </p>

        <PosterSlide />
      </div>

      <AllImages />
      {/* DİĞER POSTER PDFLERİ DE SLİDER'A KOY KAYSINLAR ORDA. */}
    </div>
  );
}
// <img loading='lazy' src={Depression} className='relative order-first p-2 3840:w-[500px] 3840:left-40 3840:-translate-y-20 top-0 max-2xl:hidden w-[400px]' />
