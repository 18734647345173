import React from "react";
import SwiperCore, { Autoplay, Navigation, Zoom, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/zoom";

import nativeManJPG from "./imgs/nativeManJPG.jpg";

import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

SwiperCore.use([Autoplay, Navigation, Pagination]);

export default function VillageRenewal(props) {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
    threshold: 0.1, // Percentage of the element that needs to be visible to trigger the animation
  });

  const animationVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  // var listOfImages =[];

  function AllImages() {
    function importAll(r) {
      return r.keys().sort().map(r);
    }

    const context1 = require.context(
      "./imgs/Pictures/VILLAGE RENEWAL",
      false
      //.(jpg)$/
    );

    const listOfImages = importAll(context1).sort();

    return (
      <div className="mx-auto">
        <div className="max-sm:grid align-middle justify-center items-center">
          {/* Swiper carousel BURAYA DINAMİC PAGİNATİON YAPMAN LAZIM */}
          <Swiper
            spaceBetween={-700}
            slidesPerView={1}
            navigation={true}
            loop={true}
            keyboard={{
              enabled: true,
            }}
            zoom={true}
            autoplay={{ delay: 6000, disableOnInteraction: false }}
            speed={500}
            centeredSlides={true}
            breakpoints={{
              // max-sm breakpoint
              3840: {
                slidesPerView: 3,
                dynamicBullets: true,
                spaceBetween: -700,
              },
              700: {
                slidesPerView: 3,
                dynamicBullets: true,
                spaceBetween: 10,
              },
              // all other breakpoints
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
            className="max-sm:w-full"
            modules={[Zoom, Navigation, Pagination]}
          >
            {listOfImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="object-contain hover:object-contain xl:object-contain px-2 mx-auto max-sm:px-2 lg:object-cover items-center w-full 3840:w-6/12 max-sm:h-96 3840:h-[20vw] h-[30vw]  max-sm:w-full py-2"
                  src={image}
                  alt="info"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  }

  const styles3 = {
    backgroundImage: `url(https://i.imgur.com/3aQRrj6.jpg)`,
    backgroundPosition: "top",
    backgroundSize: "100% 100%",
    backgroundRepeat: "repeat",
    width: "100%",
    height: "100%",
  };

  return (
    <section
      style={styles3}
      className="border-t-2 border-b-2 pt-28 border-black px-2"
    >
      <div className="başlık overflow-hidden pb-2 grid-flow-col">
        <motion.p
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="font-Manrope max-xl:p-0 pb-2 max-3xl:text-center max-xl:mx-auto max-xl:translate-x-0 max-xl:pb-5 max-xl:w-7/12 justify-start max-xl:grid rounded-3xl w-full text-center max-lg:w-11/12 text-4xl mt-10 max-xl:text-center max-sm:p-0 max-sm:mx-auto max-xl:text-5xl 3840:text-6xl max-xl:justify-center max-sm:text-2xl max-1366:mx-auto"
        >
          <b className="font-dancing whitespace-nowrap text-8xl tracking-normal max-xl:text-9xl 3840:text-9xl max-3xl:text-7xl max-sm:text-6xl font-bold max-lg:text-8xl ">
            Village Projects
          </b>
          <br></br>
        </motion.p>
      </div>

      <div className="tracking-normal max-3xl:w-11/12 max-2xl:translate-x-0 max-xl:p-0 max-xl:mx-auto max-xl:translate-x-0 max-xl:pb-5 text-slate-900 rounded-3xl w-11/12 max-4xl:translate-x-0 max-4xl:m-0 max-xl:text-base font-Antiqua max-3xl:mx-auto text-justify max-lg:py-0 max-lg:tracking-normal max-sm:text-xs mx-auto 3840:text-2xl 3840:w-8/12 max-2xl:w-11/12 max-4xl:mx-auto">
        In collaboration with various countries and international aid agencies
        such as the Canada Fund for Local Initiatives, The United Nations
        Refugee Agency, UNHCR, The Rotary Foundation, The Aal Foundation,
        private individual donations. the Maria Milagrosa Foundation, and others
        the Guyana Foundation has completed several village projects that have
        positively impacted the lives of indigenous people, women, youth,
        refugees, and the community as a whole.
        <br></br>
        <b className="font-sans">CAPOEY:</b> The Guyana Foundation in
        collaboration with the Canada Fund for Local Initiatives, The Estate of
        Glynn Ludlow Hunte completed the construction of a 15-million-dollar
        state-of-the-art bakery. Women and men benefited from free training in
        Financial Management and catering at our Sunrise Centre located in
        Essequibo. These skills were then used in this new facility which is a
        one of kind in all indigenous villages in Guyana.
        <br></br>
        <b className="font-sans">BARTICA:</b> In collaboration with UNHCR the UN
        Refugee Agency, Guyana Foundation taught sewing classes to numerous
        women who are refugee and migrants from Venezuela. We provided all
        services with bilingual staff so that maximum benefit was derived from
        this program. Participants were taught basic business management skills
        to enable them to set up micro enterprises of their own.
        <br></br>
        <b className="font-sans">PORT KAITUMA:</b> This town is located in the
        heavily forested region of Guyana and is remotely located from the
        capital city. Many migrants fleeing from Venezuela settled in this town.
        In collaboration with UNHCR, The UN Refugee agency we were able to set
        up temporary training facilities in collaboration with the Catholic
        Church to teach catering. The courses were very well attended, and all
        students were able to graduate with a certificate in catering.
        <br></br>
        <b className="font-sans">MABARUMA:</b> Located in Region 1, a remote and
        heavily forested area of Guyana, we collaborated with UNHCR the UN
        Refugee Agency to offering catering classes to women. This was well
        received as many women became sex workers to provide for their families.
        The catering certificates gave them the opportunity to sell products in
        the local market and to make earn a living to upkeep themselves and
        their families.
        <br></br>
        <div className="grid">
          <img
            src={nativeManJPG}
            alt="native indian man"
            className="max-4xl:w-[800px] py-5 w-7/12 max-3xl:block  mx-auto"
          />
        </div>
        <b className="font-sans">PIGEON ISLAND:</b> In the squatting village of
        Pigeon Island located on the East Coast of Demerara, we brought water to
        the village and provided a much-needed footbridge along with an Eye
        Screening program.
        <br></br>
        <b className="font-sans">BREEZY POINTE:</b> In Breezy Point located off
        the East Coast Public Rd, closest to Buxton, we provided solar power
        light kits to the community where there was no electrical connectivity.
        <br></br>
        <b className="font-sans">SANTA ROSA:</b> In Santa Rosa Village we
        upgraded toilet facilities for the primary school; upgraded the drinking
        water facility and we supplied blankets, curtains, and hampers to
        upgrade the dormitory.
        <br></br>
        <b className="font-sans">WAUNA:</b> We designed and built coal pot ovens
        to allow this remotely located village to open a bakery - The Silver
        Queens Bakery.
        <br></br>
        <b className="font-sans">ST IGNATIUS:</b> We arranged IT workshops in
        this village located in the Rupununi region of Guyana.
        <br></br>
        <b className="font-sans">HAGUE BACK VILLAGE:</b> We arranged wellness
        yoga classes, private counselling, and Informative workshops for
        farmers.
        <br></br>
        <b className="font-sans">WAI WAI PROJECT:</b> The Wai Wai indigenous
        people live in the remote southern part of Guyana and still maintain
        their traditional ways and culture. We placed an order for a large
        quantity of craft which was shipped abroad to the Cayman Islands for
        sale to the Cruise Ship Industry. This income greatly assisted them.
        Many other projects were undertaken too many to outline here - Areas
        such as Herstelling, Sophia, Albouystown, Blairmont, Parfaite Harmonie,
        Soesdyke, Pomeroon, and many more. Our intention is always to strengthen
        the individuals and the communities. We network to do fundraising
        locally and internationally to reach our project goals.{" "}
      </div>

      <div className="slider mt-5 ">
        <AllImages />
      </div>
    </section>
  );
}
