import React from "react";
import Card from "./Card";
import Data from "./EventsDatabase";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import Footer from "./Footer";

const youtubeLinks = [
  "https://youtu.be/GFdjFIfyaAk?si=8epT2AAkLSQsLqNy",
  "https://youtu.be/RDCJ78KFjRY",
  "https://youtu.be/SQDOZwvy8Rs",
  "https://youtu.be/eQPKJgZRDn8",
  "https://youtu.be/C-peqQX6JOc",
  "https://youtu.be/gDGXjlhyFQ8",
  "https://youtu.be/4XT3ndLnvxA",
  "https://youtu.be/FQqalctkQWY",
  "https://youtu.be/npKfzgHOvLM",
  "https://youtu.be/q192RB4J3P8",
  "https://youtu.be/UOjmlb8S0T4",
  "https://youtu.be/HISpsoK-6sE",
  "https://youtu.be/GR4yg25halo?si=9kfCZq2HOGWOfHyg",
];

export default function Galleries() {
  const styles3 = {
    backgroundImage: `url(https://i.imgur.com/3aQRrj6.jpg)`,
    backgroundPosition: "top",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    width: "100%",
    height: "100%",
  };

  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <div
      style={styles3}
      className="pt-40 border-t-2 bg-opacity-5 border-black mx-auto"
    >
      <div className="text-3xl sm:text-7xl mx-auto font-Inter font-bold items-center text-white flex justify-center">
        <h1 className="text-black text-center sm:pb-0 flex max-sm:text-center font-dancing">
          Video Galleries - Some Vocational Trainings
        </h1>
      </div>

      <div className="mx-auto items-center pt-10 lg:px-40">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {youtubeLinks.map((link, index) => (
            <SwiperSlide key={`youtubeLink_${index}`}>
              {index === activeIndex && (
                <iframe
                  title="video"
                  className="px-5 w-full h-[60vh] max-h-[1000px]"
                  src={`https://www.youtube.com/embed/${
                    link.split("https://youtu.be/")[1]
                  }`}
                  allowFullScreen
                ></iframe>
              )}
            </SwiperSlide>
          ))}
          <div className="swiper-button-next text-9xl scale-150 mr-10"></div>
          <div className="swiper-button-prev text-9xl scale-150 ml-10"></div>
        </Swiper>
      </div>

      <div className="py-2">
        {Data.map((item) => (
          <Card
            key={item.id}
            img={item.img}
            imgCard1={item.imgCard1}
            imgCard2={item.imgCard2}
            imgCard3={item.imgCard3}
            title={item.title}
            location={item.location}
            year={item.year}
            lesson={item.lesson}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}
