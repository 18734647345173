import React from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Card(props) {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
    threshold: 0.5, // Percentage of the element that needs to be visible to trigger the animation
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 1 }}
      ref={ref}
      className="container max-h-full text-black drop-shadow-2xl font-Inter px-2 mx-auto md:p-0"
    >
      <div className="shadow-lg border border-black mt-10 flex flex-wrap w-full lg:w-4/5 mx-auto">
        <div className="grid min-xl:sm:object-cover items-center max-lg:grid max-lg:items-center bg-bottom place-items-center border-black md:w-1/3 md:h-auto relative">
          <div className="xl:w-9/12 md:w-fit py-1 bg-lime-900 z-50 absolute bottom-0 transition-colors duration-200 hover:underline  max-sm:bg-opacity-100 bg-opacity-70 text-white px-5 md:text-lg max-sm:text-3xl lg:border-right lg:border-solid right-0 left-0 mx-auto md:text-left font-Inter font-medium tracking-tighter 3840:text-4xl">
            <Link to={`${props.lesson}`}>
              <h3 className="tracking-tight text-xl text-center max-sm:text-base">
                {props.title}
              </h3>
            </Link>
          </div>
          <img className="h-auto xl:h-full" src={props.img} alt="img" />
        </div>

        <div className="bg-gradient-to-b from-amber-100 to-[#ede4d2] w-full md:w-2/3">
          <div className="h-full mx-auto md:px-0 md:-ml-6 relative">
            <div className="bg-[#ede4d2] max-md:w-11/12 mx-auto lg:h-full p-6 -mt-6 md:mt-0 relative md:mb-0 flex flex-wrap md:flex-wrap items-center">
              <div className="w-full lg:w-4/5 lg:px-3">
                <div className="flex gap-1 w-4/12 h-40 sm:h-fit">
                  <img
                    src={props.imgCard1}
                    className="sm:object-cover object-contain sm:h-fit pt-5 place-self-center"
                    alt="imgCard"
                  />
                  <img
                    src={props.imgCard2}
                    className="sm:object-cover object-contain h-fit pt-5 place-self-center"
                    alt="imgCard"
                  />
                  <img
                    src={props.imgCard3}
                    className="sm:object-cover object-contain h-fit pt-5 place-self-center"
                    alt="imgCard"
                  />
                </div>
                <p className="text-xs">{props.description}</p>
              </div>

              <div className="w-full lg:w-1/5 mt-6 lg:mt-0 lg:px-4 text-center md:text-left max-sm:flex max-sm:justify-center">
                <Link to={`${props.lesson}`}>
                  <button className="flex items-center mx-auto transition-all ease justify-center px-2 py-2 text-base font-medium text-black bg-white border-2 border-transparent 3840:text-lg shadow-sm hover:bg-amber-200 active:bg-amber-800 hover:border-black hover:shadow-xl hover:drop-shadow-xl focus:outline-none">
                    See More...
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
