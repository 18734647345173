import React, { useState } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Assuming you have installed the react-icons package for the scroll-to-top arrow icon.

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    // Show the button when the user scrolls down 100 pixels from the top.
    if (window.scrollY > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Attach event listener when the component mounts.
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-10 z-50 right-10 p-2 bg-lime-600 hover:scale-125 duration-200 text-white rounded ${
        showButton ? 'block' : 'hidden'
      }`}
    >
      <FaArrowUp />
    </button>
  );
};

export default ScrollToTopButton;
