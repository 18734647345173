import React from "react";
import SwiperCore, { Autoplay, Navigation, Zoom, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/zoom";


SwiperCore.use([Autoplay, Navigation, Pagination]);

export default function Refugee() {
  function AllImages() {
    function importAll(r) {
      return r.keys().sort().map(r);
    }

    const context1 = require.context(
      "./imgs/Refugee",
      false
      //.(jpg)$/
    );

    const listOfImages = importAll(context1).sort();

    return (
      <div className="mx-auto">
        <div className="max-sm:grid align-middle justify-center items-center">
          {/* Swiper carousel BURAYA DINAMİC PAGİNATİON YAPMAN LAZIM */}
          <Swiper
            spaceBetween={-700}
            slidesPerView={1}
            navigation={true}
            loop={true}
            keyboard={{
              enabled: true,
            }}
            zoom={true}
            autoplay={{ delay: 6000, disableOnInteraction: false }}
            speed={500}
            centeredSlides={true}
            breakpoints={{
              // max-sm breakpoint
              3840: {
                slidesPerView: 3,
                dynamicBullets: true,
                spaceBetween: -700,
              },
              700: {
                slidesPerView: 3,
                dynamicBullets: true,
                spaceBetween: 10,
              },
              // all other breakpoints
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
            className="max-sm:w-full"
            modules={[Zoom, Navigation, Pagination]}
          >
            {listOfImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="object-contain hover:object-contain xl:object-contain px-2 mx-auto max-sm:px-2 lg:object-cover items-center w-full 3840:w-6/12 max-sm:h-96 3840:h-[20vw] h-[30vw]  max-sm:w-full py-2"
                  loading="lazy"
                  src={image}
                  alt="info"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  }

  const styles3 = {
    backgroundImage: `url(https://i.imgur.com/3aQRrj6.jpg)`,
    backgroundPosition: "top",
    backgroundSize: "100% 50%",
    backgroundRepeat: "repeat",
    width: "100%",
    height: "100%",
  };

  return (
    <section
      style={styles3}
      className="py-10 border-t-2 border-b-2 border-black"
    >

<h1 className='text-2xl pb-5 sm:text-6xl mx-auto text-center font-extrabold'>Refugee and Migrant Assistance Program</h1>

    <AllImages />
    </section>
  )
}
